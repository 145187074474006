import AuthService from './Auth.service';

export default function authHeader() {
    const user = AuthService.GetCookie();

    if (user) {
        return { Authorization: 'Bearer ' + user };
    } else {
        if (process.env.NODE_ENV === "production")
            if (process.env.REACT_APP_PRENV === "false")
                window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_FI_WEBAPP&solution=AGRF_FI');
            else
                window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_FI_WEBAPP&solution=AGRF_FI&env=pre');
        else
            window.location.href = '/login';
    }
}
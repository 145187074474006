import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Skeleton } from "@progress/kendo-react-indicators";
import ingredientsService from '../../services/ingredients.service';
import formulasService from '../../services/formulas.service';
import Ingredients from '../../models/formulas/Ingredients';

export default function IngredientsDetails(props) {

    //#region Hooks

    const { t } = useTranslation();
    const { params } = props.match;
    const [loading, setLoading] = React.useState(true);
    const [formulaCode, setFormulaCode] = React.useState("");
    const [VisibleError, setVisibleError] = React.useState(false);
    const [ingredients, setIngredients] = React.useState([]);
    const [ErrorData, setErrorData] = React.useState("");

    //#endregion

    //#region Effects

    React.useEffect(async () => {
        setLoading(true);

        try {
            var site_formula = await formulasService.getFormula(params.siteId, params.plantCode, params.formulaId)

            setFormulaCode(site_formula.data.contract.formulaCode);

            var api_ingredients = await ingredientsService.getIngredients(params.siteId, params.plantCode, params.formulaId);

            setIngredients(api_ingredients.data.contract);

        }
        catch (err) {

            setVisibleError(true);
            setErrorData({ messageError: err.response.data.message ?? err, code: err.response.status ?? "Error" });
        }

        finally {
            setLoading(false);
        }

    }, [params]);

    //#endregion

    return (
        <div>
            <br />

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/formulas/'}>{t("Formulas_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/sites/' + params.siteId + '/plantcodes/' +
                        params.plantCode + "/formulas/" + params.formulaId + '/details'}>{loading ? <Skeleton style={{ padding: "0 0 0 50px", margin: "0px 0px 0px 10px" }} /> : formulaCode}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/sites/' + params.siteId + '/plantcodes/' +
                        params.plantCode + "/formulas/" + params.formulaId + '/details'}>{t("Details_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>

            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/details'} className="nav-link">{t("Details_Breadcrumb_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/ingredients'} className="nav-link active">{t("Ingredients_Breadcrumb_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/nutrients'} className="nav-link">{t("Nutrients_Breadcrumb_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Ingredients ingredients={ingredients} errorData={ErrorData} errorDataFunc={setErrorData} visibleError={VisibleError} visibleErrorFunc={setVisibleError} loadingData={loading} loadingDataFunc={setLoading} formulaCodeFunc={setFormulaCode} siteId={params.siteId} plantCode={params.plantCode} formulaId={params.formulaId} />
                </div>
            </div>
            <br />
        </div >
    );

}

import * as React from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { CustomInput } from "../../components/CustomComponents"
import dashboardsService from '../../services/dashboards.service';

export default function Edit(props) {

    const { t } = useTranslation();

    const [dashboard, setDashboard] = React.useState([]);

    const [loading, setLoading] = React.useState(true);
    const [VisibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });


    React.useEffect(async() => {
        setLoading(true);
  
        try
        {
            var api_dashboard = (await dashboardsService.getAdministrationDashboard()).data.contract;
            setDashboard(api_dashboard);
        }
        catch(err)
        {
            setVisibleError(true);
            setErrorData({ messageError: err.response.data, code: err.response.status });
        }
        finally
        {
            setLoading(false);
        }

      }, [props]);

    return (
        <div>
            {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <div className="card">
                <div className="card-header">{t("Sites_Breadcrumb_Title")}</div>
                <div className="card-body">

                    <div className="row">
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="NumberSites" name="NumberSites"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Overview_sites")}
                                    disabled={true}  value={ dashboard.numberOfSites  }/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="NumberSitesWithoutConnector" name="NumberSitesWithoutConnector"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Overview_sites_no_connector")}
                                    disabled={true}  value={ dashboard.numberOfSitesWithoutUploaderAssignedUser === 0 ? '0': 
                                                             dashboard.numberOfSitesWithoutUploaderAssignedUser}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>

            <div className="card">
                <div className="card-header">{t("Users_Breadcrumb_Title")}</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="Users" name="Users"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Overview_users")}
                                    disabled={true}  value={ dashboard.numberOfUsers  }/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="UsersWithoutConnector" name="UsersWithoutConnector"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Overview_users_no_domain_users")}
                                    disabled={true}  value={ dashboard.numberOfUsersWithoutUserAssigned === 0? '0' : 
                                                             dashboard.numberOfUsersWithoutUserAssigned }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>

            <div className="card">
                <div className="card-header">{t("Formulas_Breadcrumb_Title")}</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="Formulas" name="Formulas"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Formulas_Breadcrumb_Title")}
                                    disabled={true}  value={ dashboard.numberOfformulas  }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
}

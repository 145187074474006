import * as React from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";
import { CustomInput, CustomDateInput } from "../../components/CustomComponents"
import dashboardsService from '../../services/dashboards.service';

export default function Edit(props) {

    const { t } = useTranslation();

    const [dashboard, setDashboard] = React.useState([]);
    const [sysInfo, setSysInfo] = React.useState([]);

    const [loading, setLoading] = React.useState(true);
    const [VisibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });


    React.useEffect(async () => {
        setLoading(true);

        try {
            var api_dashboard = (await dashboardsService.getHomeDashboard()).data.contract;
            api_dashboard.lastUpdate = new Date(api_dashboard.lastUpdate);
            setDashboard(api_dashboard);
        }
        catch (err) {
            setVisibleError(true);
            setErrorData({ messageError: err.response.data, code: err.response.status });
        }
        finally {
            setLoading(false);
        }

    }, [props]);

    React.useEffect(async () => {
        setLoading(true);

        try {
            var api_sysInfo = (await dashboardsService.getSystemInformation()).data.contract;
            api_sysInfo.databaseLastUpdate = new Date(api_sysInfo.databaseLastUpdate)
            setSysInfo(api_sysInfo);
        }
        catch (err) {
            setVisibleError(true);
            setErrorData({ messageError: err.response.data, code: err.response.status });
        }
        finally {
            setLoading(false);
        }

    }, [props]);

    return (
        <div>

            {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <div className="card">
                <div className="card-header">{t("Overview_title")}</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="NumberSites" name="NumberSites"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Overview_sites")}
                                    disabled={true} 
                                    value={dashboard.numOfSites} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="NumberPlants" name="NumberPlants"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Overview_plants")}
                                    disabled={true} 
                                    value={dashboard.numOfPlants} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="NumberFormulas" name="NumberFormulas"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Overview_formulas")}
                                    disabled={true} 
                                    value={dashboard.numOfFormulas} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-4">
                            <div>
                                <CustomDateInput 
                                    width={'100%'}
                                    id="lastUpdate" name="lastUpdate"
                                    format="dd/MM/yyyy HH:mm"
                                    placeholder={""}
                                    loading={loading}
                                    label={t("Overview_lastUpdate")}
                                    value={dashboard.lastUpdate} 
                                    />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="LastUpdatedSite" name="LastUpdatedSite"
                                    type={"string"}
                                    loading={loading}
                                    label={t("Formulas_lastUpdatedSiteId")}
                                    disabled={true} 
                                    value={dashboard.lastUpdatedSiteDescription} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-header">{t("SystemInformation_title")}</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="apiVersion" name="apiVersion"
                                    type={"string"}
                                    loading={loading}
                                    label={t("SysInfo_APIVerison")}
                                    disabled={true} 
                                    value={sysInfo.apiVersion} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <CustomInput
                                    width={'100%'}
                                    id="databaseVersion" name="databaseVersion"
                                    type={"string"}
                                    loading={loading}
                                    label={t("SysInfo_DBVersion")}
                                    disabled={true} 
                                    value={sysInfo.databaseVersion} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <CustomDateInput
                                    width={'100%'}
                                    id="databaseLastUpdate" name="databaseLastUpdate"
                                    placeholder={""}
                                    loading={loading}
                                    format="dd/MM/yyyy HH:mm"
                                    label={t("SysInfo_DBLastUpdate")}
                                value={sysInfo.databaseLastUpdate} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

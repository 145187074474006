import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import plantsService from "../../services/plants.service";

export default function SPlants(props) {
  //#region Definitions
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [VisibleError, setVisibleError] = React.useState(false);
  const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });
  const [plants, setPlants] = React.useState([]);

  React.useEffect(async () => {
    setLoading(true);

    try
      {
        var api_plants = (await plantsService.getPlants(props.siteId)).data.contract;
        setPlants(api_plants);
      }
      catch(err)
      {
          setVisibleError(true);
          setErrorData({ messageError: err.response.data, code: err.response.status });
      }
      finally
      {
          setLoading(false);
      }
  }, [props]);

  const CommandCell = cellProps => (
    <CustomListCommandCellWithRemove
        {...cellProps}
        editField='inEdit'
        linkToDetails={'/administration/sites/' + cellProps.dataItem.siteId + '/plants/' + cellProps.dataItem.plantCode + '/details'}
    />
  );

  return <div>
    {loading && loadingPanel}

    {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

    <div className="card">
        <Grid data={plants} width={'100%'}>
          <Column cell={CommandCell} sortable={false} filterable={false} width="80px"/>
          <Column field="plantCode" title={t("IngredientDetails_Code")} sortable={false} filterable={false}/>
          <Column field="name" title={t("Sites_SitePlants_PlantName")} sortable={false} filterable={false}/>
        </Grid>

      </div>
  </div>;
}

import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sites from '../../models/admin/Sites';

export default function AdministrationDetails() {

    const { t } = useTranslation();

    return (
        <div>
            <br />

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/administration/'}>{t("Administration_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/administration/sites/'}>{t("Sites_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>
            <div>
                <Sites />
            </div>
            <br />
        </div >
    );

}

import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput  } from "../../components/CustomComponents"
import { DropDownList } from "@progress/kendo-react-dropdowns";
import SitesService from "../../services/sites.service";
import usersConnectorService from '../../services/usersConnector.service';
import { useTranslation } from "react-i18next";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function NSite() {

    const loadingPanel = (
        <div className="k-loading-mask">
          <span className="k-loading-text">Loading</span>
          <div className="k-loading-image"></div>
          <div className="k-loading-color"></div>
        </div>
      );

    const { t } = useTranslation();

    const site = {
        "uploaderUserDomainId" : 0,
        "fidesktopId" : "",
        "isDiabled" : false,
        "name" : "",
        "remarks" : ""        
    };
    
    const [selectedUser, setSelectedUser] = React.useState({userId:-1, username:"", isDisabled:false, domainUserId:-1, domainUserName:""});
    const [userConnectors, setUserConnectors] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [VisibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    React.useEffect(async () => {
        setLoading(true);
    
            try
            {
              var api_users_connector = await usersConnectorService.getUsersConnector();
              setUserConnectors((api_users_connector).data.contract)
            }
            catch(err)
            {
                setVisibleError(true);
                setErrorData({ messageError: err.response.data, code: err.response.status });
            }
            finally
            {
                setLoading(false);
            }
      }, []);

    const requiredValidator = (value) => {
        return value ? "" : t("Validator_FieldIsRequired_Text");
    }

    const handleSubmit = (dataItem) => {

        setLoading(true);

        SitesService.insertSite({
            "uploaderUserDomainId" : dataItem.userDomainId.userDomainId,
            "fidesktopId" : "",
            "name" : dataItem.name,
            "remarks" : dataItem.remarks,
            "isDisabled" : false   
        }).then(end => {
           return window.location.href = "/administration/sites/" + end.data.contract.siteId + "/details";
        }     

        );

        setLoading(false);
    }

    return (
        <div>
            {loading && loadingPanel}

            {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
            <Form
                onSubmit={handleSubmit}
                initialValues={site}
                key={JSON.stringify(site)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                        <div className="card">
                                <div className="card-header">{t("Users_Breadcrumb_Details")}</div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Field
                                                label={t("SDetails_SiteName")}
                                                name={'name'}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <DropDownList
                                                label={t("Sites_Connector")}
                                                name="userDomainId"
                                                textField="username"
                                                data={userConnectors}
                                                dataItemKey="userDomainId"
                                                style={{ width: '100%' }}
                                                value={selectedUser}
                                                onChange={e =>{setSelectedUser(e.value);
                                                        formRenderProps.onChange("userDomainId",{value:e.value})}}/>
                                        </div>
                                    </div>
                                    <br/>
                                        
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Field
                                                label={t("Sites_Remarks")}
                                                name={'remarks'}
                                                component={CustomInput}
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
            </LocalizationProvider>
        </div>
    );
}

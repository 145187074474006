import * as React from 'react';
import sitesService from '../../services/sites.service';
import usersConnectorService from '../../services/usersConnector.service';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { CustomInput  } from "../../components/CustomComponents"
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import Skeleton from 'react-loading-skeleton';
import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function SDetails(props) {

    const { t } = useTranslation();

    const [selectedUser, setSelectedUser] = React.useState({username:"", userDomainId:-1});
    const [userConnectors, setUserConnectors] = React.useState([]);
    const [site, setSite] = React.useState({"siteId": 0,
                                            "name": "",
                                            "isDisabled": true,
                                            "creationDate": "2022-10-07T08:49:29.450Z",
                                            "lastChanges": "2022-10-07T08:49:29.450Z",
                                            "fidesktopId": "",
                                            "connector" : "",
                                            "uploaderUserDomainId": 0,
                                            "remarks": ""});

    const [loading, setLoading] = React.useState(true);
    const [VisibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    React.useEffect(async() => {
        setLoading(true);

        try
        {
            var api_site = (await sitesService.getSite(props.siteId)).data.contract;
            var api_domainUsers = (await usersConnectorService.getUsersConnector()).data.contract;
            var userConnectorIndex = api_domainUsers.findIndex(item => item.userDomainId === api_site.uploaderUserDomainId)

            setUserConnectors(api_domainUsers)
            setSelectedUser(api_domainUsers[userConnectorIndex])

            let d = new Date(api_site["creationDate"]);
            let dateString = ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
            api_site["creationDate"] = dateString

            d = new Date(api_site["lastChanges"]);
            dateString = ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
            api_site["lastChanges"] = dateString

            setSite(api_site);
        }
        catch(err)
        {
            setVisibleError(true);
            setErrorData({ messageError: err.response.data, code: err.response.status });
        }
        finally
        {
            setLoading(false);
        }
      }, [props]);

    
      const requiredValidator = (value) => {
        return value ? "" : t("Validator_FieldIsRequired_Text");
    }

    const handleSubmit = (dataItem) => {

        setLoading(true);

        sitesService.updateSite({
            "siteId" : site.siteId,
            "name" : dataItem.name,
            "isDisabled" : site.isDisabled,
            "creationDate" : site.creationDate,
            "lastChanges" : site.lastChanges,
            "fidesktopId" : site.fidesktopId,
            "uploaderUserDomainId" : selectedUser.userDomainId,
            "remarks" : site.remarks,
            "connector" : site.connector
        }).then(end => {
           return window.location.href = "/administration/sites/" + end.data.contract.siteId + "/details";
        }     

        );

        setLoading(false);
    }

    return (
        <div>

            {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
            <Form
                onSubmit={handleSubmit}
                initialValues={site}
                key={JSON.stringify(site)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Field
                                                label={t("SDetails_SiteName")}
                                                name={'name'}
                                                loading={loading}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                        {loading ? <Skeleton /> :<DropDownList
                                                label={t("Sites_Connector")}
                                                name="userDomainId"
                                                textField="username"
                                                data={userConnectors}
                                                dataItemKey="userDomainId"
                                                width= {'100%'}
                                                loading={loading}
                                                value={selectedUser}
                                                onChange={e =>{setSelectedUser(e.value);
                                                        formRenderProps.onChange("userDomainId",{value:e.value})}}/>}
                                        </div>
                                    </div>
                                    <br/>
                                        
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Field
                                                disabled="true"
                                                label={t("Sites_CreationDate")}
                                                name={'creationDate'}
                                                loading={loading}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <Field
                                                disabled="true"
                                                label={t("Sites_UpdateDate")}
                                                name={'lastChanges'}
                                                component={CustomInput}
                                                loading={loading}
                                                validator={[requiredValidator]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </fieldset>
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
            </LocalizationProvider>
        </div>
    );
}

import React from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function DropDownGridCell(props) {

    let { field, dataItem, onChange } = props.cellProps;
    let userConnectors = props.userConnectors;

    const value = dataItem[field] === null ? "" : dataItem[field];

    const onSelectedSite = (event) => {
        if (onChange) {
            onChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value,
            });
        }
    };

    const selectedValue = () => {      
        return value !== undefined ? userConnectors.find(e => e.userDomainId === value.userDomainId) : null;
    }

    const textValue = () => {
        return  (value !== undefined ? value.username : "" );
    }

    return (
        <td>
            <div>
                {dataItem.inEdit ? (<DropDownList
                    data={userConnectors}
                    value={selectedValue()}
                    textField="username"
                    dataItemKey="userDomainId"
                    style={{ width: '150px' }}
                    onChange={onSelectedSite}
                />) : textValue().toString()}
            </div>
        </td>
    );



}

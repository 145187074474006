import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UDetails from '../../models/admin/UDetails';

export default function UsersDetails(props) {

  const { t } = useTranslation();
  const { params } = props.match;

  return (
    <div>
      <br />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
          <li className="breadcrumb-item"><Link to={'/administration/'}>{t("Administration_Breadcrumb_Title")}</Link></li>
          <li className="breadcrumb-item"><Link to={'/administration/users/'}>{t("Users_Breadcrumb_Title")}</Link></li>
          <li className="breadcrumb-item"><Link to={'/administration/users/details/' + params.userId}>{params.userId}</Link></li>
          <li className="breadcrumb-item"><Link to={'/administration/users/details/' + params.userId}>{t("Users_Breadcrumb_Details")}</Link></li>
        </ol>
      </nav>
      <UDetails userId={params.userId} />
      <br />
    </div>
  );
}

import * as React from 'react';
import usersService from '../../services/users.service';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import sitesService from '../../services/sites.service';
import plantsService from '../../services/plants.service';
import usersDomainService from '../../services/usersDomain.service';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CustomInput  } from "../../components/CustomComponents"
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import Skeleton from 'react-loading-skeleton';
import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function UDetails(props) {

    const { t } = useTranslation();

    const [user, setUser] = React.useState({"userId": 0, "domainUserId": 0, "domainUserName": "", 
                                            "username": "", "isDisabled": true});
    const [loading, setLoading] = React.useState(true);
   
    const [VisibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const [doaminUsers, setDomainUsers] = React.useState([]);
    const [selectedDomainUser, setSelectedDomainUser] = React.useState({userDomainId:0, username:""});

    React.useEffect(async() => {
        setLoading(true);

        try
        {
            var api_user = (await usersService.getUser(props.userId)).data.contract;
            var api_sites = (await sitesService.getSites()).data.contract;

            var api_domain_users = (await usersDomainService.getUsersDomain()).data.contract;
            setDomainUsers(api_domain_users)

            var domainUserIndex = api_domain_users.findIndex(item => item.userDomainId === api_user.domainUserId)

            setDomainUsers(api_domain_users)
            setSelectedDomainUser(api_domain_users[domainUserIndex])

            var _assignedSite = 0
            var _assignedPlants = 0
    
            for (const site of api_sites)
            {
                if(site.uploaderUserDomainId === api_user.domainUserId)
                {
                    _assignedSite += 1
                    _assignedPlants += ((await plantsService.getPlants(site.siteId)).data.contract).lenght;
                }
            }
    
            api_user["sitesCount"] = _assignedSite.toString()
            api_user["plantsCount"] = _assignedPlants.toString()

            console.log(api_user)
            setUser(api_user);
        }
        catch(err)
        {
            setVisibleError(true);
            setErrorData({ messageError: err.response.data, code: err.response.status });
        }
        finally
        {
            setLoading(false);
        }

      }, [props]);

      const requiredValidator = (value) => {
        return value ? "" : t("Validator_FieldIsRequired_Text");
    }

    const handleSubmit = (dataItem) => {

        setLoading(true);

        usersService.updateUser({
            "userId" : user.userId,
            "domainUserId" : selectedDomainUser.userDomainId,
            "domainUserName" : selectedDomainUser.username,
            "username" : dataItem.username,
            "isDisabled" : user.isDisabled   
        }).then(end => {
           return window.location.href = "/administration/users/" + end.data.contract.userId + "/details";
        }     

        );

        setLoading(false);
    }

    return (
        <div>
 
            {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
            <Form
                onSubmit={handleSubmit}
                initialValues={user}
                key={JSON.stringify(user)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                        <div className="card">
                            <div className="card-header">{t("Users_Breadcrumb_Details")}</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Field
                                            label={t("UDetails_Username")}
                                            name={'username'}
                                            component={CustomInput}
                                            loading={loading}
                                            validator={[requiredValidator]}
                                        />
                                    </div> 

                                    <div className="col-lg-6">
                                    {loading ? <Skeleton /> : <DropDownList
                                                label={t("Sites_Connector")}
                                                name="userDomainId"
                                                textField="username"
                                                data={doaminUsers}
                                                dataItemKey="userDomainId"
                                                width={'100%'}
                                                value={selectedDomainUser}
                                                loading={loading}
                                                onChange={e =>{setSelectedDomainUser(e.value);
                                                        formRenderProps.onChange("userDomainId",{value:e.value})}}/> }
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Field
                                            label={t("UDetails_AssignedSites")}
                                            name={'sitesCount'}
                                            component={CustomInput}
                                            loading={loading}
                                            disabled="true"
                                            />
                                    </div>
                                    <div className="col-lg-6">
                                        <Field
                                            label={t("UDetails_AssignedPlants")}
                                            name={'plantsCount'}
                                            loading={loading}
                                            component={CustomInput}
                                            disabled="true"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </fieldset>
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
            </LocalizationProvider>
        </div>
    );
}

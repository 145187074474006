import * as React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import plantsService from '../../services/plants.service';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CustomInput  } from "../../components/CustomComponents"
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function SPlantDetails(props) {

    const { t } = useTranslation();
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
      );

    const [plant, setPlant] = React.useState({"siteId": 0, "plantCode": 0, "name": "", 
                                            "description": "", "lastChanges": "2022-11-23T16:58:32.051Z"});
    const [loading, setLoading] = React.useState(true);
    const [VisibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    React.useEffect(async() => {
        setLoading(true);

        try
        {
            var api_plant = (await plantsService.getPlant(props.siteId, props.plantCode)).data.contract;
            setPlant(api_plant)
        }
        catch(err)
        {
            setVisibleError(true);
            setErrorData({ messageError: err.response.data, code: err.response.status });
        }
        finally
        {
            setLoading(false);
        }

      }, [props]);

      const requiredValidator = (value) => {
        return value ? "" : t("Validator_FieldIsRequired_Text");
    }

    const handleSubmit = (dataItem) => {

        setLoading(true);

        plantsService.updatePlant({
            "siteId" : plant.siteId,
            "plantCode" : plant.plantCode,
            "name" : dataItem.name,
            "description" : dataItem.description,
            "lastChanges" : plant.lastChanges,
        }).then(end => {
           return window.location.href = "/administration/sites/" + end.data.contract.siteId + "/plants/" + end.data.contract.plantCode + "/details";
        }     
        );

        setLoading(false);
    }

    return (
        <div>
            {loading && loadingPanel}

            {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
            <Form
                onSubmit={handleSubmit}
                initialValues={plant}
                key={JSON.stringify(plant)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Field
                                            label={t("Formulas_Details_PlantCode")}
                                            name={'plantCode'}
                                            component={CustomInput}
                                            validator={[requiredValidator]}
                                            disabled="true"
                                        />
                                    </div> 

                                    <div className="col-lg-6">
                                        <Field
                                            label={t("Sites_SitePlants_PlantName")}
                                            name={'name'}
                                            component={CustomInput}
                                            />
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Field
                                            label={t("Formulas_Details_Description")}
                                            name={'description'}
                                            component={CustomInput}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
            </LocalizationProvider>
            <br/>
        </div>
    );
}

import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Skeleton } from "@progress/kendo-react-indicators";

import Nutrients from '../../models/formulas/Nutrients';
import formulasService from '../../services/formulas.service';
import nutrientsService from '../../services/nutrients.service';

export default function NutrientsDetails(props) {

    //#region  "Hooks"

    const { t } = useTranslation();
    const { params } = props.match;
    const [formulaCode, setFormulaCode] = React.useState("");
    const [nutrients, setNutrients] = React.useState([]);
    const [ErrorData, setErrorData] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [VisibleError, setVisibleError] = React.useState(false);

    //#endregion

    //#region "Effects"

    React.useEffect(async () => {
        try {
            var site_formula = await formulasService.getFormula(params.siteId, params.plantCode, params.formulaId)

            setFormulaCode(site_formula.data.contract.formulaCode);

            var api_nutrients = (await nutrientsService.getNutrients(params.siteId, params.plantCode, params.formulaId)).data.contract;

            var collator = new Intl.Collator([], { numeric: true });
            api_nutrients.sort((a, b) => collator.compare(a.nutrientCode, b.nutrientCode));

            setNutrients(api_nutrients);
        }
        catch (err) {
            setLoading(false);
            setVisibleError(true);
            setErrorData({ messageError: err.response.data.message ?? err, code: err.response.status ?? "Error" });
        }
        finally {
            setLoading(false);
        }

    }, [params]);

    //#endregion

    return (
        <div>
            <br />

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/formulas/'}>{t("Formulas_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/sites/' + params.siteId + '/plantcodes/' +
                        params.plantCode + "/formulas/" + params.formulaId + '/details'}>{loading ? <Skeleton style={{ padding: "0 0 0 50px", margin: "0px 0px 0px 10px" }} /> : formulaCode}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/sites/' + params.siteId + '/plantcodes/' +
                        params.plantCode + "/formulas/" + params.formulaId + '/details'}>{t("Details_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>

            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/details'} className="nav-link">{t("Details_Breadcrumb_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/ingredients'} className="nav-link">{t("Ingredients_Breadcrumb_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/nutrients'} className="nav-link active">{t("Nutrients_Breadcrumb_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Nutrients VisibleError={VisibleError} visibleErrorFunc={setVisibleError} ErrorData={ErrorData} nutrients={nutrients} loadingFunc={loading} loadingDataFunc={setLoading} formulaCodeFunc={setFormulaCode} siteId={params.siteId} plantCode={params.plantCode} formulaId={params.formulaId} />
                </div>
            </div>
            <br />
        </div>
    );

}
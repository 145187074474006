import * as React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Checkbox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CustomInput } from "../../components/CustomComponents"
import formulasService from '../../services/formulas.service';

export default function Edit(props) {

    const { t } = useTranslation();

    const [formula, setFormula] = React.useState({"siteId": 0, "formulaId": "", 
                                                    "formulaCode": "", 
                                                    "plantCode": "", 
                                                    "version": 0, 
                                                    "description": "", 
                                                    "batchWeight": 0, 
                                                    "costWhenStored": 0, 
                                                    "speciesCode": 0, 
                                                    "storeDate": Date.now.toJSON, 
                                                    "logUser": "", 
                                                    "logDate": Date.now.toJSON, 
                                                    "pricingPlant": "", 
                                                    "effectiveDate": Date.now.toJSON, 
                                                    "expirationDate": Date.now.toJSON, 
                                                    "formulaSpecId": "", 
                                                    "comment": "", 
                                                    "isDisabled": true, 
                                                    "isProduction": true, 
                                                    "changeDate": Date.now.toJSON});

    const [loading, setLoading] = React.useState(true);
    const [VisibleError, setVisibleError] = React.useState(false);
    const [ErrorData, setErrorData] = "";
    const toggleDialog = () => {
        setVisibleError(false);
      };

    React.useEffect(async() => {
        setLoading(true);
  
        try
        {
            var api_formula = await formulasService.getFormula(props.siteId, props.plantCode, props.formulaId);
            setFormula(api_formula.data.contract);

            if(props.formulaCodeFunc !== undefined)
                props.formulaCodeFunc(api_formula.data.contract.formulaCode);
        }
        catch(err)
        {
            setVisibleError(true);
            setErrorData({ messageError: err.response.data.message ?? err, code: err.response.status ?? "Error" });
        }
        finally
        {
            setLoading(false);
            props.loadingDataFunc(false);
        }
      }, [props]);

    return (
        <div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">{t("Formulas_Details_General")}</div>
                <div className="card-body">

                    {VisibleError && (
                    <Dialog title={"Error"} onClose={toggleDialog}>
                        <p style={{margin: "25px", textAlign: "center",}}>
                            {ErrorData}
                        </p>
                        <DialogActionsBar>
                            <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={toggleDialog}>
                            OK
                            </button>
                        </DialogActionsBar>
                    </Dialog> )}

                    <div className="row">
                        <div className="col-lg-3">
                            <div>
                                <CustomInput 
                                        width={'100%'}
                                        loading={loading}
                                        id="PlantCode" name="PlantCode"
                                        type={"string"}
                                        label={t("Formulas_Details_PlantCode")}
                                        disabled={true}  value={ formula.plantCode  }/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div>
                            <CustomInput
                                id="FormulaCode" name="FormulaCode"
                                width={'100%'}
                                loading={loading}
                                type={"string"}
                                label={t("Formulas_Details_FormulaCode")}
                                disabled={true}  
                                value={ formula.formulaCode  }/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div>
                                <CustomInput 
                                            width={'100%'}
                                            id="Version" name="Version"
                                            loading={loading}
                                            type={"string"}
                                            label={t("Formulas_Details_Version")}
                                            disabled={true}  
                                            value={ formula.version  }/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <label>{t("Formulas_Details_Production")}</label>
                            <br/>
                            <Checkbox value={ formula.isProduction } />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <CustomInput
                                id="Description" name="Description"
                                width={'100%'}
                                type={"string"}
                                loading={loading}
                                label={t("Formulas_Details_Description")}
                                disabled={true}  value={ formula.description  }/>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <CustomInput
                                id="BatchWeight" name="BatchWeight"
                                width={'100%'}
                                loading={loading}
                                type={"string"}
                                label={t("Formulas_Details_BatchNumber")}
                                disabled={true}  value={ formula.batchWeight  }/>
                        </div>
                    </div>
                </div>
            </div>


            <br/>

            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">{t("Formulas_Details_Dates")}</div>
                <div className="card-body">

                    <div className="row">
                        <div className="col-lg-4">
                            <CustomInput
                                id="StoreDate" name="StoreDate"
                                width={'100%'}
                                loading={loading}
                                label={t("Formulas_Details_StoreDate")}
                                disabled={true}  
                                value={ formula.storeDate  }/>
                        </div>

                        <div className="col-lg-4">
                            <CustomInput
                                id="ExpirationDate" name="ExpirationDate"
                                width={'100%'}
                                loading={loading}
                                label={t("Formulas_Details_ExpirationDate")}
                                disabled={true}  
                                value={ formula.expirationDate  }/>
                        </div>

                        <div className="col-lg-4">
                            <CustomInput
                                id="EffectiveDate" name="EffectiveDate"
                                width={'100%'}
                                loading={loading}
                                label={t("Formulas_Details_EffectiveDate")}
                                disabled={true}  
                                value={ formula.effectiveDate  }/>
                        </div>
                    </div>
                </div>
            </div>

            <br/>

            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">{t("Formulas_Details_Cost")}</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                    <CustomInput
                                        id="FormulaDetailsCost" name="FormulaDetailsCost"
                                        width={'100%'}
                                        type={"string"}
                                        loading={loading}
                                        label={t("Formulas_Details_Cost")}
                                        disabled={true}  value={ formula.costWhenStored  }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

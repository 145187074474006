import axios from 'axios';
import authHeader from './authHeader';
import AuthService from './Auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class PlantsService {

    getPlants(SiteId){
        return axiosApiInstanceForValidation.get(API_URL + '/v3/sites/' + SiteId + '/plants/', { headers: authHeader() });
    }

    getPlant(SiteId, plantCode){
        return axiosApiInstanceForValidation.get(API_URL + '/v3/sites/' + SiteId + '/plants/' + plantCode, { headers: authHeader() });
    }

    getSitePlantUsers(siteId, plantCode){
        return axiosApiInstanceForValidation.get(API_URL + '/v4/sites/' + siteId + '/plants/' + plantCode + '/users', { headers: authHeader() });
    }

    addUserToSitePlant(siteId, plantCode, userId)
    {
        return axiosApiInstanceForValidation.post(API_URL + '/v4/sites/' + siteId + '/plants/' + plantCode + '/users', { userId : userId }, { headers: authHeader() });
    }

    updatePlant(plant){
        return axiosApiInstanceForValidation.put(API_URL + '/V4/sites/'+plant.siteId + '/plants/' + plant.plantCode, plant, { headers: authHeader() });
    }

    removeUserFromSitePlant(siteId, plantCode, userId)
    {
        return axiosApiInstanceForValidation.delete(API_URL + '/v4/sites/' + siteId + '/plants/' + plantCode + '/users/' + userId, { headers: authHeader() });
    }

}

export default new PlantsService();
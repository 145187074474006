import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle } from '@progress/kendo-react-charts';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import 'hammerjs';

export default function Ingredients(props) {

  //#region Definitions
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const initialDataState = {
    skip: 0,
    take: 10,
  };

  //#endregion

  //#region Hooks

  const [dataState, setDataState] = React.useState(initialDataState);
  const [data, setData] = React.useState([]);
  const { t } = useTranslation();
  const [ingredientSeries, setIngredientSeries] = React.useState([]);

  //#endregion

  //#region Effects

  React.useEffect(async () => {

    if (props.ingredients)
      try {
        var tempArray = [];
        var total_amount = 0

        props.ingredients.forEach(element => {
          total_amount += element.amount;
        });


        props.ingredients.forEach(element => {
          var tmp = {}
          tmp.ingredientCode = element.ingredientCode;
          tmp.category = element.description;
          tmp.value = element.amount / total_amount;
          tmp.amount = element.amount;
          tmp.costPerUnit = element.costPerUnit;

          tmp.percentage = tmp.value * 100 + "%"
          tempArray.push(tmp)
        });

        setIngredientSeries(tempArray)
        setData(process(tempArray, { skip: 0, take: 10, }));
      }
      catch (err) {
        console.log(err)
        props.visibleErrorFunc(true);
        props.errorDataFunc({ messageError: err.response.data.message ?? err, code: err.response.status ?? "Error" });
      }

      finally {
        props.loadingDataFunc(false);
      }

  }, [props.ingredients]);

  //#endregion

  //#region Functions

  const toggleDialog = () => {
    props.visibleErrorFunc(false);
  };

  const loadingCell = (tdElement, cellProps) => {
    if (cellProps.dataItem[cellProps.field] === undefined) {
      // shows loading cell if no data
      return <td> <span className="k-placeholder-line"></span></td>;
    }

    // default rendering for this cell
    return tdElement;
  };

  const dataStateChange = event => {
    setData(process(props.ingredients, event.dataState));
    setDataState(event.dataState);
  };

  const labelContent = props => {
    let formatedNumber = Number(props.dataItem.value).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 2
    });
    return `${props.dataItem.category} -Amount: ${formatedNumber}`;
  };

  //#endregion

  return <div>

    <Chart>
      <ChartTitle text={t("Ingredients_Percentaje")} />
      <ChartLegend position="bottom" />
      <ChartSeries>
        <ChartSeriesItem type="pie" data={ingredientSeries} field="value" categoryField="category"
          labels={{
            visible: true,
            content: labelContent
          }} />
      </ChartSeries>
    </Chart>

    {props.loadingData && loadingPanel}

    {props.visibleError && (
      <Dialog title={"Error"} onClose={toggleDialog}>
        <p style={{ margin: "25px", textAlign: "center", }}>
          {props.errorData}
        </p>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={toggleDialog}>
            OK
          </button>
        </DialogActionsBar>
      </Dialog>)}

    <div>
      <Grid
        width={'100%'}
        editField='inEdit'
        cellRender={loadingCell}
        pageable={{
          pageSizes: [10, 20, 50, 100],
        }}
        data={data}
        {...dataState}
        onDataStateChange={dataStateChange}
        scrollable={'none'}
        filterable={false}
        sortable={true}>
        <Column field="ingredientCode" title={t("Ingredients_Code")} sortable={false} filterable={false} />
        <Column field="category" title={t("Ingredients_Description")} sortable={false} filterable={false} />
        <Column field="percentage" title="%" sortable={false} filtrable={false}/>
        <Column field="amount" title={t("Ingredients_Amount")} filterable={false} sortable={true} />
        <Column field="costPerUnit" title={t("Ingredients_UnitaryCost")} filterable={false} />
      </Grid>
    </div>

  </div>;
}
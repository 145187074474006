import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SUsers from '../../models/admin/SUsers.js';

export default function SiteDetails(props) {

  const { t } = useTranslation();
  const { params } = props.match;

  return (
    <div>
      <br />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
            <li className="breadcrumb-item"><Link to={'/administration/'}>{t("Administration_Breadcrumb_Title")}</Link></li>
            <li className="breadcrumb-item"><Link to={'/administration/sites/'}>{t("Sites_Breadcrumb_Title")}</Link></li>
            <li className="breadcrumb-item"><Link to={'/administration/sites/' + params.siteId + '/details' }>{params.siteId}</Link></li>
            <li className="breadcrumb-item"><Link to={'/administration/sites/' + params.siteId + '/users'}>{t("Sites_SiteUsers")}</Link></li>
        </ol>
      </nav>

      <div className="card text-center">
        <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <Link to={'/administration/sites/' + params.siteId + '/details' } className="nav-link">{t("Users_Breadcrumb_Details")}</Link>
                </li>
                <li className="nav-item">
                    <Link to={'/administration/sites/' + params.siteId + '/users'} className="nav-link active">{t("Sites_SiteUsers")}</Link>
                </li>
                <li className="nav-item">
                    <Link to={'/administration/sites/' + params.siteId + '/plants' } className="nav-link">{t("Sites_SitePlants")}</Link>
                </li>
            </ul>
        </div>
        <div className="card-body">
          <SUsers siteId={params.siteId}/>
        </div>
      </div>
      <br />
    </div>
  );
}

import * as React from 'react';
import { useTranslation } from "react-i18next";

export const GridCustomRemoveCommandCell = props => {
    const { dataItem } = props;
    const { t } = useTranslation();

    return (<td className="k-command-cell">
        <button className="k-button k-grid-remove-command" onClick={() => { props.remove(dataItem); }} >
            &nbsp;&nbsp;<span className=" k-icon k-i-delete" title={t("Global_Delete_Text")}></span>&nbsp;&nbsp;
        </button>
    </td>
    );
};
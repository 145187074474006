import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import usersDomainService from '../../services/usersDomain.service';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { GridCustomRemoveCommandCell } from "../../components/GridCustomRemove";
import { Loader } from "@progress/kendo-react-indicators";
import sitesService from "../../services/sites.service";


export default function SUsers(props) {
  //#region Definitions
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [VisibleError, setVisibleError] = React.useState(false);
  const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });
  const [siteUsers, setSiteUsers] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [usersToDelete, setUsersToDelete] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState({ userId: -1 });
  const [saving, setSaving] = React.useState(false);


  React.useEffect(async () => {
    setLoading(true);

    try {
      var api_users_site = (await sitesService.getSiteUsers(props.siteId)).data.contract;
      var api_users_domain = (await usersDomainService.getUsersDomain()).data.contract;

      for (const user of api_users_site) {
        user.isInDB = user.selected ? true : false;
        var userConnector = api_users_domain.filter(obj => {
          return obj.userDomainId === user.domainUserId
        })

        if (userConnector.length > 0)
          user.connector = userConnector[0].username;

      }

      setUsers(api_users_site.filter(x => x.selected !== true));
      setFilteredUsers(api_users_site.filter(x => x.selected !== true));
      setSiteUsers(api_users_site.filter(x => x.selected === true));

    }
    catch (err) {
      setVisibleError(true);
      setErrorData({ messageError: err.response || err, code: "500" });
    }
    finally {
      setLoading(false);
    }

  }, [props.siteId]);

  const filterData = (filter) => {
    const data = users.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setFilteredUsers(filterData(event.filter));
  };

  const remove = (dataItem) => {
    setFilteredUsers([...filteredUsers, { userId: dataItem.userId, connector: dataItem.connector, username: dataItem.username, isInDB: dataItem.isInDB }]);
    setSiteUsers(siteUsers.filter(item => item.userId !== dataItem.userId));
    if (dataItem.isInDB)
      setUsersToDelete([...usersToDelete, { userId: dataItem.userId, connector: dataItem.connector, username: dataItem.username }]);
  }

  const addSiteUser = () => {
    if (selectedUsers.userId !== -1) {
      // remove selected user from site users
      setFilteredUsers(filteredUsers.filter(user => user.userId !== selectedUsers.userId));
      // set siteUsers
      setSiteUsers([...siteUsers, { userId: selectedUsers.userId, connector: selectedUsers.connector, username: selectedUsers.username, isInDB: selectedUsers.isInDB }]);
      // set null item
      setSelectedUsers({ userId: -1, name: '' });
      // remove from delete user if exists
      setUsersToDelete(usersToDelete.filter(item => item.userId !== selectedUsers.userId));
    }
  };

  const saveSiteUsers = async () => {
    setSaving(true);
    try {

      for (let user of usersToDelete) {
        let result = await sitesService.removeUserFromSite(props.siteId, user.userId);
        if (result.status === 200)
          setUsersToDelete(usersToDelete.filter(x => x.userId === user.userId));
      }

      for (let user of siteUsers)
        if (!user.isInDB)
          await sitesService.addUSerToSite(props.siteId, user.userId);

      var api_users_site = (await sitesService.getSiteUsers(props.siteId)).data.contract;
      var api_users_domain = (await usersDomainService.getUsersDomain()).data.contract;

      for (const user of api_users_site) {
        user.isInDB = user.selected ? true : false;
        var userConnector = api_users_domain.filter(obj => {
          return obj.userDomainId === user.domainUserId
        })

        if (userConnector.length > 0)
          user.connector = userConnector[0].username;

      }

      setUsers(api_users_site.filter(x => x.selected !== true));
      setFilteredUsers(api_users_site.filter(x => x.selected !== true));
      setSiteUsers(api_users_site.filter(x => x.selected === true));

      setSaving(false);

    } catch (err) {
      setVisibleError(true);
      setErrorData({ messageError: err.response || err, code: "500" });
      setSaving(false);
    }
  };

  const CommandCell = cellProps => (
    <GridCustomRemoveCommandCell
      {...cellProps}
      editField='inEdit'
      remove={remove}
    />
  );

  return <div>
    {loading && loadingPanel}

    {VisibleError && (
      <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
        <p style={{ margin: "25px", textAlign: "center" }}>
          <b>{errorData.messageError}</b>
        </p>
        <DialogActionsBar>
          <button className="k-button" onClick={() => { setVisibleError(false) }}>
            OK
          </button>
        </DialogActionsBar>
      </Dialog>
    )}

    <div>

      <div className="row">
        <div className="col-3">
          <DropDownList
            label={t("Sites_Edit_Users_Label")}
            name="users"
            textField="username"
            dataItemKey="userId"
            value={selectedUsers}
            style={{ width: "100%" }}
            filterable={true}
            onFilterChange={filterChange}
            onChange={e => {
              var obt = filteredUsers.find(x => x.userId === e.value.userId);
              if (obt)
                setSelectedUsers(obt);
            }}
            data={filteredUsers}
          />
        </div>
        <div className="col-1">
          <Button
            style={{ marginTop: "20px" }}
            onClick={addSiteUser}
            disabled={selectedUsers.userId === -1}
          >{t("Global_Button_Text_Add")}</Button>
        </div>
      </div>

      <br />

      <Grid
        width={'100%'}
        data={siteUsers}>
        <Column cell={CommandCell} width={"80px"} sortable={false} />
        <Column field="username" title={t("Users_Username")} sortable={false} filterable={false} />
        <Column field="connector" title={t("Sites_Connector")} editable={true} sortable={false} filterable={false} />
      </Grid>

    </div>
    <div className="float-end k-form-buttons">
      {saving ?
        <button
          type={'submit'}
          disabled={true}
          className="k-button">
          <Loader size="small" type="converging-spinner" themeColor="inverse" /> &nbsp; {t("Global_Button_Text_Save")}
        </button>
        :
        <button
          type={'submit'}
          onClick={saveSiteUsers}
          className="k-button"
        >
          <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
        </button>}
    </div>
  </div>;
}

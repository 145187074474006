import * as React from "react";
import { useTranslation } from "react-i18next";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { process } from '@progress/kendo-data-query';
import sitesService from '../../services/sites.service';
import plantsService from '../../services/plants.service';
import formulasService from '../../services/formulas.service';

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export default function List() {

  //#region Definitions
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const { t } = useTranslation();

  const [selectedSite, setSelectedSite] = React.useState(getSessionStorageOrDefault('selectedSite', { "siteId": -1, "name": "Select a site...", "remarks": "" }));
  const [selectedPlant, setSelectedPlant] = React.useState(getSessionStorageOrDefault('selectedPlant', { "siteId": -1, "plantCode": "Select a plant...", "name": "", "description": "" }));
  const [sites, setSites] = React.useState(getSessionStorageOrDefault("sites", []));
  const [plants, setPlants] = React.useState(getSessionStorageOrDefault("plants", []));
  const [formulas, setFormulas] = React.useState(getSessionStorageOrDefault("formulas", []));
  const hasSite = selectedSite.siteId !== -1;
  const hasPlant = selectedPlant.siteId !== -1;
  const [loading, setLoading] = React.useState(true);
  const [VisibleError, setVisibleError] = React.useState(false);
  const [ErrorData, setErrorData] = "";
  const toggleDialog = () => {
    setVisibleError(false);
  };

  React.useEffect(async () => {
    setLoading(true);

    try {
      if (selectedSite.siteId == -1 && selectedPlant.siteId == -1) {
        var api_sites = await sitesService.getSites();
        sessionStorage.setItem('sites', JSON.stringify(api_sites.data.contract));
        setSites(api_sites.data.contract);
      }

      else {
        formulas.map((item) => (item.changeDate = new Date(item.changeDate)));
        setData(process(formulas, { skip: 0, take: 10, }));
      }
    }
    catch (err) {
      setVisibleError(true);
      setErrorData({ messageError: err.response.data.message ?? err, code: err.response.status ?? "Error" });
    }

    finally {
      setLoading(false);
    }

  }, []);

  const loadingCell = (tdElement, cellProps) => {
    if (cellProps.dataItem[cellProps.field] === undefined) {
      // shows loading cell if no data
      return <td> <span className="k-placeholder-line"></span></td>;
    }

    // default rendering for this cell
    return tdElement;
  };

  const CommandCell = cellProps => (
    <CustomListCommandCellWithRemove
      {...cellProps}
      editField='inEdit'
      //remove={remove} /Formulas/Details/?FormulaId=4b96677f-6d10-4435-a9ac-c2bd521dace1&SiteId=1&PlantCode=0101
      linkToDetails={'/sites/' + cellProps.dataItem.siteId + '/plantcodes/' +
        cellProps.dataItem.plantCode + "/formulas/" + cellProps.dataItem.formulaId + '/details'}
    />
  );

  const ProductionHeader = () => (<span className="k-icon k-i-wrench" title={t("IngredientDetails_IsProduction")}></span>);

  const ProductionCell = cellProps => (
    <td className="k-command-cell">
      {cellProps.dataItem.isProduction ?
        <span className="k-icon k-i-gear" title={t("IngredientDetails_IsProduction_InProduction_Tooltip")}></span> :
        <span className="k-icon k-i-folder" title={t("IngredientDetails_IsProduction_NotProduction_Tooltip")}></span>
      }
    </td>
  );


  const initialDataState = {
    skip: 0,
    take: 10,
  };

  const [dataState, setDataState] = React.useState(initialDataState);
  const [data, setData] = React.useState([]);

  const dataStateChange = event => {
    setData(process(formulas, event.dataState));
    setDataState(event.dataState);
  };
  //#endregion Definitions

  //#region Events

  const onSelectedSite = async (event) => {

    setLoading(true);

    try {
      setSelectedSite(event.value);
      sessionStorage.setItem('selectedSite', JSON.stringify(event.value));
      var site_plants = await plantsService.getPlants(event.value.siteId);
      sessionStorage.setItem('plants', JSON.stringify(site_plants.data.contract));
      setPlants(site_plants.data.contract);

      if (site_plants.data.contract.length > 0) {
        setSelectedPlant(site_plants.data.contract[0]);
      }

      else {
        setSelectedPlant({ "siteId": -1, "plantCode": "Select a plant...", "name": "", "description": "" });
        setFormulas([]);
        setData([]);
      }
    }
    catch (err) {
      setVisibleError(true);
      setErrorData({ messageError: err.response.data.message ?? err, code: err.response.status ?? "Error" });
    }

    finally {
      setLoading(false);
    }
  }

  const onSelectedPlant = async (event) => {
    setSelectedPlant(event.value);
    sessionStorage.setItem('selectedPlant', JSON.stringify(event.value));
  }

  const filterEvent = async () => {

    setLoading(true);

    try {
      var site_plant_formulas = await formulasService.getFormulas(selectedSite.siteId, selectedPlant.plantCode);
      site_plant_formulas.data.contract.map((item) => (item.changeDate = new Date(item.changeDate)));
      setData(process(site_plant_formulas.data.contract, { skip: 0, take: 10, }));
      sessionStorage.setItem('formulas', JSON.stringify(site_plant_formulas.data.contract));
      site_plant_formulas.data.contract.forEach(c => c.changeDate = new Date(c.changeDate));
      setFormulas(site_plant_formulas.data.contract);
    }
    catch (err) {
      setVisibleError(true);
      setErrorData({ messageError: err.response.data.message ?? err, code: err.response.status ?? "Error" });
    }

    finally {
      setLoading(false);
    }
  }
  //#endregion Events

  return <div>
    {loading && loadingPanel}
    {VisibleError && (
      <Dialog title={"Error"} onClose={toggleDialog}>
        <p style={{ margin: "25px", textAlign: "center", }}>
          {ErrorData}
        </p>
        <DialogActionsBar>
          <Button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={toggleDialog}>
            OK
          </Button>
        </DialogActionsBar>
      </Dialog>)}
    <div className="card">
      <div className="card-body">
        <div className="carheaderd- d-flex justify-content-between align-items-center">

          <div>
            <DropDownList
              label={t("IngredientDetails_Site")}
              style={{
                width: '300px',
                margin: '0px 20px 0px 0px'
              }} data={sites} textField="name" onChange={onSelectedSite} value={selectedSite} />

            <DropDownList style={{ width: '300px' }}
              label={t("IngredientDetails_Plantcode")}
              disabled={!hasSite} data={plants} textField="plantCode" onChange={onSelectedPlant} value={selectedPlant} />
          </div>

          <Button
            onClick={filterEvent}
            disabled={!hasPlant}
            className="k-button"
          >
            <span className="k-icon k-i-filter" /> &nbsp; {t("Global_Button_Text_Filter")}
          </Button>
        </div>

      </div>
    </div>
    <br />
    <div>
      <Grid
        width={'100%'}
        editField='inEdit'
        cellRender={loadingCell}
        pageable={{
          pageSizes: [10, 20, 50, 100],
        }}
        data={data}
        {...dataState}
        onDataStateChange={dataStateChange}
        scrollable={'none'}
        sortable={true}>
        <Column cell={CommandCell} sortable={false} filterable={false} width="80px" />
        <Column cell={ProductionCell} title={<ProductionHeader />} width="40px" sortable={false} filterable={false} />
        <Column field="formulaCode" title={t("IngredientDetails_Code")} sortable={false} filterable={false} />
        <Column field="version" title={t("IngredientDetails_Version")} sortable={false} filterable={false} width="80px" />
        <Column field="description" title={t("IngredientDetails_Description")} sortable={false} filterable={false} />
        <Column field="changeDate" title={t("IngredientDetails_Date")} format="{0: dd/MM/yyyy HH:mm}" filterable={false} />
      </Grid>

    </div>

  </div>;
}


import axios from 'axios';
import authHeader from './authHeader';
import AuthService from './Auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class SitesService {

    getSites(){
        return axiosApiInstanceForValidation.get(API_URL + '/V4/Sites', { headers: authHeader() });
    }

    getSite(siteId){
        return axiosApiInstanceForValidation.get(API_URL + '/v4/Sites/' + siteId, { headers: authHeader() });
    }

    getSiteUsers(siteId){
        return axiosApiInstanceForValidation.get(API_URL + '/v4/sites/' + siteId + '/users', { headers: authHeader() });
    }

    addUSerToSite(siteId, userId)
    {
        console.log("Adding " + userId)
        return axiosApiInstanceForValidation.post(API_URL + '/v4/sites/' + siteId + '/users', { userId : userId }, { headers: authHeader() });
    }

    removeUserFromSite(siteId, userId)
    {
        return axiosApiInstanceForValidation.delete(API_URL + '/v4/sites/' + siteId + '/users/' + userId, { headers: authHeader() });
    }

    insertSite(site){
        return axiosApiInstanceForValidation.post(API_URL + '/V4/Sites', site, { headers: authHeader() });
    }

    updateSite(site){
        return axiosApiInstanceForValidation.put(API_URL + '/V4/Sites/'+site.siteId, site, { headers: authHeader() });
    }

    deleteSite(site){
        return axiosApiInstanceForValidation.delete(API_URL + '/V4/Sites/'+ site.siteId, { headers: authHeader() });
    }
}

export default new SitesService();

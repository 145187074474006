import React, { Component } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { Input } from "@progress/kendo-react-inputs";

import AuthService from "../services/Auth.service";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            username: "",
            password: "",
            loading: false,
            message: ""
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.login(this.state.username, this.state.password).then(
                () => {
                    this.props.history.push("/");
                    window.location.reload();
                },
                error => {


                    let resMessage = "";
                    if (error.response && error.response.data.statusMessage) {
                        resMessage = error.response.data.statusMessage;
                    } else {
                        resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }



                    this.setState({
                        loading: false,
                        message: resMessage
                    });
                }
            );
        } else {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return (
            <div>
                <br />
                <br />
                <div className="col-md-12 d-flex justify-content-center">
                    <div className=" col-md-6">
                        {this.state.message && (
                            <div className="form-group">
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {this.state.message}
                                </div>
                            </div>
                        )}
                        <Form
                            onSubmit={this.handleLogin}
                            ref={c => {
                                this.form = c;
                            }}
                        >
                            <div className="form-group">
                                <Input
                                    type="text"
                                    label="Username"
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    name="username"
                                    autoComplete="username"
                                    value={this.state.username}
                                    onChange={this.onChangeUsername}
                                    validations={[required]}
                                />
                            </div>
                            <br />
                            <div className="form-group">
                                <Input
                                    type="password"
                                    style={{ width: "100%" }}
                                    label="Password"
                                    className="form-control"
                                    name="password"
                                    autoComplete="current-password"
                                    value={this.state.password}
                                    onChange={this.onChangePassword}
                                    validations={[required]}
                                />
                            </div>
                            <br />
                            <div className="form-group float-end">
                                <button
                                    className="btn btn-primary btn-block"
                                    disabled={this.state.loading}
                                >
                                    {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Login</span>
                                </button>
                                <CheckButton
                                    style={{ display: "none" }}
                                    ref={c => {
                                        this.checkBtn = c;
                                    }}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

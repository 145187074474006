import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { process } from '@progress/kendo-data-query';

export default function Nutrients(props) {

  //#region Definitions

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const initialDataState = {
    skip: 0,
    take: 10,
  };

  //#endregion

  //#region Hooks
  const { t } = useTranslation();

  const [dataState, setDataState] = React.useState(initialDataState);
  const [data, setData] = React.useState([]);
  const [nutrients, setNutrients] = React.useState([]);

  //#endregion

  //#region Effects

  React.useEffect(async () => {
    setData(process(props.nutrients, { skip: 0, take: 10, }));
    setNutrients(props.nutrients);
  }, [props]);

  //#endregion

  //#region Functions

  const toggleDialog = () => {
    props.visibleErrorFunc(false);
  };
  const loadingCell = (tdElement, cellProps) => {
    if (cellProps.dataItem[cellProps.field] === undefined) {
      // shows loading cell if no data
      return <td> <span className="k-placeholder-line"></span></td>;
    }

    // default rendering for this cell
    return tdElement;
  };

  const dataStateChange = event => {
    setData(process(nutrients, event.dataState));
    setDataState(event.dataState);
  };

  //#endregion

  return <div>

    {props.loadingFunc && loadingPanel}

    {props.VisibleError && (
      <Dialog title={"Error"} onClose={toggleDialog}>
        <p style={{ margin: "25px", textAlign: "center", }}>
          {props.ErrorData}
        </p>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={toggleDialog}>
            OK
          </button>
        </DialogActionsBar>
      </Dialog>)}

    <div>
      <Grid
        windth={'100%'}
        editField='inEdit'
        cellRender={loadingCell}
        pageable={{
          pageSizes: [10, 20, 50, 100],
        }}
        data={data}
        {...dataState}
        onDataStateChange={dataStateChange}
        scrollable={'none'}
        filterable={false}
        sortable={true}>
        <Column field="nutrientCode" title={t("Nutrients_Code")} sortable={false} filterable={false} />
        <Column field="description" title={t("Nutrients_Description")} sortable={false} filterable={false} />
        <Column field="amount" title={t("Nutrients_Amount")} filterable={false} />
        <Column field="units" title={t("Nutrients_Units")} filterable={false} />
      </Grid>

    </div>

  </div>;
}


import * as React from 'react';
import { Drawer, DrawerContent, DrawerItem } from '@progress/kendo-react-layout';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import AuthService from '../services/Auth.service.js';
import { useTranslation } from "react-i18next";

const CustomItem = (props) => {
    const { visible, ...others } = props;
    const arrowDir = props['data-expanded'] ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
    return (
        <React.Fragment>
            {visible === false ? null :
                <DrawerItem {...others} >
                    <span className={'k-icon ' + props.icon} />
                    <p className={'k-item-text'}></p> {props.text}
                    {props['data-expanded'] !== undefined && <span className={"k-icon " + arrowDir} style={{ position: "absolute", right: 5 }} />}
                </DrawerItem>}
        </React.Fragment>
    );
};

function DrawerRouterContainer(props) {

    const { t } = useTranslation();

    const [items, setItems] = React.useState([]);

    React.useEffect(() => {

        var user = AuthService.getCurrentUser();
        var itemsMenu = [];

        if (user) {
            itemsMenu = [
                { text: t("Home_Title"), id: 0, icon: 'k-i-home', route: '/' },
                { separator: true },
                { text: t("Formulas_Title"), id: 1, icon: 'k-i-grid-layout', route: '/Formulas' },
                { separator: true },
                { text: t("Administration_Title"), id: 2, icon: 'k-i-style-builder', 'data-expanded': false },
                { text:t("Users_Breadcrumb_Details"), id: 6, parentId: 2, route: '/administration' },
                { text:t("Overview_sites"), id: 7, parentId: 2, route: '/administration/sites/'},
                { text:t("Overview_users"), id: 8, parentId: 2, route: '/administration/users/'},
            ];

        }
        else {
            itemsMenu = [
                
            ];

        }

        setItems(itemsMenu);

    }, [localStorage.getItem("i18nextLng")]);

    const [state, setState] = React.useState({ drawerExpanded: window.innerWidth > 768, isSmallerScreen: window.innerWidth < 768 });

    const handleClick = () => {
        setState({ drawerExpanded: !state.drawerExpanded, isSmallerScreen: state.isSmallerScreen });
    };

    const onSelect = (ev) => {
        const currentItem = ev.itemTarget.props;
        const isParent = currentItem['data-expanded'] !== undefined;
        const nextExpanded = !currentItem['data-expanded'];

        const newData = items.map((item) => {
            const { selected, 'data-expanded': currentExpanded, id, ...others } = item;
            const isCurrentItem = currentItem.id === id;
            console.log(selected);
            return {
                selected: isCurrentItem,
                'data-expanded': isCurrentItem && isParent ? nextExpanded : currentExpanded,
                id,
                ...others
            };
        });

        if (!isParent && (window.innerWidth < 768))
            setState({ drawerExpanded: !state.drawerExpanded, isSmallerScreen: state.isSmallerScreen });

        setItems(newData);
        props.history.push(ev.itemTarget.props.route);
    }


    const data = items.map((item) => {
        const { parentId, ...others } = item;
        if (parentId !== undefined) {
            const parent = items.find(Parentitem => Parentitem.id === parentId)
            return {
                ...others,
                visible: parent['data-expanded']
            };
        }
        return item;
    });

    return (
        <div>
            <NavMenu onButtonClick={handleClick} />
            <Drawer
                expanded={state.drawerExpanded}
                mode={state.isSmallerScreen ? 'overlay' : 'push'}
                // mini={true}
                style={{ height: "100vh" }}
                items={data}
                position='start'
                item={CustomItem}
                onSelect={onSelect}
            >
                <DrawerContent>
                    <div>
                        <Container>
                            {props.children}
                        </Container>
                    </div>
                </DrawerContent>
            </Drawer>
        </div >
    );

}

export default withRouter(DrawerRouterContainer);
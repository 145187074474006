import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput  } from "../../components/CustomComponents"
import UsersService from "../../services/users.service";
import usersDomainService from '../../services/usersDomain.service';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function NUser() {

    const [allowSubmission, setAllowSubmission] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [VisibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });
    const [doaminUsers, setDomainUsers] = React.useState([]);
    const [selectedDomainUser, setSelectedDomainUser] = React.useState({userDomainId:0, username:""});

    const loadingPanel = (
        <div className="k-loading-mask">
          <span className="k-loading-text">Loading</span>
          <div className="k-loading-image"></div>
          <div className="k-loading-color"></div>
        </div>
      );

    const { t } = useTranslation();

    const user = {
        "domainUserId" : 0,
        "username" : "",
        "isDisabled" : false        
    };


    React.useEffect(async () => {
        setLoading(true);
    
            try
            {
              var api_domain_users = await usersDomainService.getUsersDomain();
              setDomainUsers((api_domain_users).data.contract);
            }
            catch(err)
            {
                setVisibleError(true);
                setErrorData({ messageError: err.response.data, code: err.response.status });
            }
            finally
            {
                setLoading(false);
            }
      }, []);
    
    const requiredValidator = (value) => {
        return value ? "" : t("Validator_FieldIsRequired_Text");
    }

    const handleSubmit = (dataItem) => {

        setLoading(true);

        UsersService.insertUser({
            "domainUserId" : dataItem.userDomainId.userDomainId,
            "username" : dataItem.name,
            "isDisabled" : false   
        }).then(end => {
           return window.location.href = "/administration/users/" + end.data.contract.userId + "/details";
        }     

        );

        setLoading(false);
    }

    return (
        <div>
             {loading && loadingPanel}

             {VisibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
            <Form
                onSubmit={handleSubmit}
                initialValues={user}
                key={JSON.stringify(user)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                        <div className="card">
                            <div className="card-header">{t("Users_Breadcrumb_Details")}</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Field
                                            label={t("UDetails_Username")}
                                            name={'name'}
                                            component={CustomInput}
                                            validator={[requiredValidator]}
                                        />
                                    </div> 
                                    
                                    <div className="col-lg-6">
                                            <DropDownList
                                                label={t("Sites_Connector")}
                                                name="userDomainId"
                                                textField="username"
                                                data={doaminUsers}
                                                dataItemKey="userDomainId"
                                                width = {'100%'}
                                                value={selectedDomainUser}
                                                onChange={
                                                            e =>{
                                                                    setSelectedDomainUser(e.value);
                                                                    formRenderProps.onChange("userDomainId",{value:e.value})
                                                                    setAllowSubmission(true);
                                                                }
                                                        }/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!allowSubmission}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
            </LocalizationProvider>
        </div>
    );
}

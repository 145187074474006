import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import DropDownGridCell from "../../components/DropDownGridCell";
import { Link } from 'react-router-dom'
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";
import usersService from '../../services/users.service';
import usersDomainService from '../../services/usersDomain.service';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export default function Users() {
  //#region Definitions
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const { t } = useTranslation();
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [VisibleError, setVisibleError] = React.useState(false);
  const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

  const [userConnectors, setUserConnectors] = React.useState([]);

  React.useEffect(async () => {
    setLoading(true);

    try {
      var api_users = (await usersService.getUsers()).data.contract;
      var api_users_domain = await usersDomainService.getUsersDomain();
      setUserConnectors(api_users_domain.data.contract);
      for (const user of api_users) {
        var api_domainUsers = (api_users_domain).data.contract;

        var userConnector = api_domainUsers.filter(obj => {
          return obj.userDomainId === user.domainUserId
        })

        user["connector"] = userConnector[0]
      }

      setUsers(api_users);
    }
    catch (err) {
      setVisibleError(true);
      setErrorData({ messageError: err.response.data, code: err.response.status });
    }
    finally {
      setLoading(false);
    }
  }, []);

  const CommandCell = cellProps => (
    <CustomListCommandCellWithRemove
      {...cellProps}
      editField='inEdit'
      remove={remove}
      linkToDetails={'/administration/users/' + cellProps.dataItem.userId + '/details/'}
      isAdmin={true}
    />
  );

  const remove = async (dataItem) => {
    setLoading(true);
    usersService.deleteUser(dataItem).then(() => {
      usersService.getUsers()
        .then(response => response.data)
        .then(json => {
          json.contract.forEach(async user => {
            var userConnector = userConnectors.filter(obj => {
              return obj.userDomainId === user.domainUserId
            })

            user["connector"] = userConnector[0]
          });
          setUsers(json.contract);
          setLoading(false);
        });
    }).catch(error => {
      setVisibleError(true);
      setLoading(false);
      if (error.response.data)
        setErrorData({ messageError: error.response.data.statusMessage, code: error.response.data.status })
      else
        setErrorData({ messageError: error.message, code: "500" })
    });
  }

  const dropDownUsers = (cellProps) => { return <DropDownGridCell cellProps={cellProps} userConnectors={userConnectors} /> };

  return <div>

    {loading && loadingPanel}

    {VisibleError && (
      <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
        <p style={{ margin: "25px", textAlign: "center" }}>
          <b>{errorData.messageError}</b>
        </p>
        <DialogActionsBar>
          <button className="k-button" onClick={() => { setVisibleError(false) }}>
            OK
          </button>
        </DialogActionsBar>
      </Dialog>
    )}

    <div>
      <Grid
        width={'100%'}
        editField={"inEdit"}
        data={users}
        scrollable={'none'}
        sortable={true}>
        <GridToolbar>
          <Link to='/administration/users/add' >
            <Button className="k-button k-primary" title={t("Users_NewUser_Tooltip")}><span className="k-icon k-i-plus-circle" />&nbsp; {t("Users_NewUser")} &nbsp;</Button>
          </Link>
        </GridToolbar>
        <Column cell={CommandCell} sortable={false} editable={false} filterable={false} width="160px" />
        <Column field="username" title={t("Users_Username")} sortable={false} filterable={false} />
        <Column field="connector" cell={dropDownUsers} title={t("Sites_Connector")} editable={true} sortable={false} filterable={false} />
      </Grid>

    </div>

  </div>;
}

import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import List from '../../models/formulas/List';

export default function Formulas() {

  const { t } = useTranslation();

  return (
    <div>
      <br />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
          <li className="breadcrumb-item"><Link to={'/sites/'}>{t("Formulas_Breadcrumb_Title")}</Link></li>
        </ol>
      </nav>
        <List/>
      <br />
    </div>
  );
}

import React from 'react';
import { Link } from "react-router-dom";
import Auth from "../services/Auth.service";
import { useTranslation } from "react-i18next";
import Overview from '../models/home/Overview';

export default function Home() {

    const { t } = useTranslation();

    return (
        <div>
            {Auth.isUserLoged ? <div>
                <br />
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    </ol>
                </nav>

                <div>
                    <div>
                        <Overview />
                    </div>
                </div>
                <br />
            </div > : ""}
        </div>
    );

}

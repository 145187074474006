import React from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { avaiableLangList } from '../i18nextConf'
import i18next from "i18next";

export default function SelectableLanguageDropDown() {

    const [selectedLanguage, setSelectedLanguage] = React.useState({ "id": "en-GB", "name": "English", "short": "en" });

    React.useEffect(() => {

        var selected = localStorage.getItem("i18nextLng") || "en";
        var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
        setSelectedLanguage(selectedLangDetails);


    }, []);

    const onSelectedLanguage = (event) => {
        i18next.changeLanguage(event.value.short);

        var selectedLangDetails = avaiableLangList.find(lang => lang.short === event.value.short);
        setSelectedLanguage(selectedLangDetails);

    }

    return (
        <div>
            <DropDownList
                data={avaiableLangList}
                value={selectedLanguage}
                textField="name"
                dataItemKey="id"
                style={{ width: '150px' }}
                onChange={onSelectedLanguage}
            />
        </div>
    )
}

import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Edit from '../../models/formulas/Edit';
import { Skeleton } from "@progress/kendo-react-indicators";

export default function FormulasDetails(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [formulaCode, setFormulaCode] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    return (
        <div>
            <br />

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/formulas/'}>{t("Formulas_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/sites/' + params.siteId + '/plantcodes/' +
                        params.plantCode + "/formulas/" + params.formulaId + '/details'}>{loading ? <Skeleton style={{ padding: "0 0 0 50px", margin: "0px 0px 0px 10px" }} /> : formulaCode}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/sites/' + params.siteId + '/plantcodes/' +
                        params.plantCode + "/formulas/" + params.formulaId + '/details'}>{t("Details_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>

            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/details'} className="nav-link active">{t("Details_Breadcrumb_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/ingredients'} className="nav-link">{t("Ingredients_Breadcrumb_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/sites/' + params.siteId + '/plantcodes/' + params.plantCode + "/formulas/" + params.formulaId + '/nutrients'} className="nav-link">{t("Nutrients_Breadcrumb_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Edit loadingDataFunc={setLoading} formulaCodeFunc={setFormulaCode} siteId={params.siteId} plantCode={params.plantCode} formulaId={params.formulaId} />
                </div>
            </div>
            <br />
        </div >
    );

}

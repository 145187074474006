import axios from 'axios';
import authHeader from './authHeader';
import AuthService from './Auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class UserConnectorService {

    getUsersConnector(){
        return axiosApiInstanceForValidation.get(API_URL + '/v4/usersConnector', { headers: authHeader() });
    }
}

export default new UserConnectorService();

import React from 'react';
import { Link } from "react-router-dom";
import AuthService from "../services/Auth.service";
import { useTranslation } from "react-i18next";
import SelectableLanguageDropDown from "./SelectableLanguageDropDown";

export default function NavMenu(props) {

    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = React.useState(null);
    const { onButtonClick } = props;

    React.useEffect(() => {

        let user = AuthService.getCurrentUser();

        setCurrentUser(user);

    }, []);

    const logOut = (e) => {
        AuthService.logout();
        e.preventDefault();
    }

    const logIn = (e) => {
        console.log(process.env.NODE_ENV);
        if (process.env.NODE_ENV === "production")
            if (process.env.REACT_APP_PRENV === "false")
                window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_FI_WEBAPP&solution=AGRF_FI');
            else
                window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_FI_WEBAPP&solution=AGRF_FI&env=pre');
        else
            window.location.href = '/login';
        e.preventDefault();
    }

    return (
        <div>
            <nav className="navbar navbar-expand navbar-dark" style={{ backgroundColor: "#7EB424" }}>
                <div className="container-fluid">
                    <a className="navbar-brand" onClick={onButtonClick} ><img src="https://cdn.agrifoodatsolutions.com/img/fi_webapp_logo.png" height="40" width="40" alt="Agrifood Formula Integration" />
                        {window.innerWidth > 1024 ?
                            <span style={{ padding: '0 10px 0' }} >Formula Integration</span>
                            : ""}
                    </a>

                    {currentUser ? (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item" style={{ padding: "4px 8px" }}>
                                <SelectableLanguageDropDown />
                            </li>
                            <li className="nav-item">
                                <Link to={"/profile"} className="nav-link" style={{}}>{t("Global_Label_Text_Profile")}</Link>
                            </li>
                            <li className="nav-item">
                                <a href="/login" className="nav-link" onClick={(e) => logOut(e)}>{t("Global_Label_Text_Logout")}</a></li>
                        </div>
                    ) : (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to={"/login"} className="nav-link" onClick={(e) => logIn(e)}>{t("Global_Label_Text_Login")}</Link>
                            </li>
                        </div>
                    )}
                </div>
            </nav>
        </div>
    );

}

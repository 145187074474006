
import React from 'react';

import './App.scss';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.css';
//import './styleKendo.css';

import { Route, Switch } from "react-router-dom";
import DrawerRouterContainer from './components/DrawerRouterContainer';
import Home from './pages/Home';
import Login from "./pages/Login";
import Formulas from "./pages/formulas/Formulas";
import version from './version'
import FormulasDetails from './pages/formulas/Details';
import FormulaIngredientsDetails from './pages/formulas/IngredientsDetails';
import FormulaNutrientsDetails from './pages/formulas/NutrientsDetails';
import Administration from './pages/administration/Details';
import AdministrationSites from './pages/administration/Sites';
import AdministrationUsers from './pages/administration/Users';
import AdministrationNewSite from './pages/administration/NewSite';
import AdministrationNewUser from './pages/administration/NewUser';
import AdministrationUserDetails from './pages/administration/UserDetails';
import AdministrationSitesDetails from './pages/administration/SiteDetails';
import AdministrationSitesUsers from './pages/administration/SiteUsers';
import AdministrationSitesPlants from './pages/administration/SitePlants';
import SitePlantUsers from './pages/administration/SitePlantUsers';
import SitePlantDetails from './pages/administration/SitePlantDetails';

function App() {
  return (
    <div>
      <DrawerRouterContainer>
        <Switch>
          <Route exact path={["/", "/home"]} component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/formulas" component={Formulas} />
          <Route exact path="/administration" component={Administration} />
          <Route exact path="/administration/sites" component={AdministrationSites} />
          <Route exact path="/administration/users" component={AdministrationUsers} />
          <Route exact path="/administration/sites/add" component={AdministrationNewSite} />
          <Route exact path="/administration/users/add" component={AdministrationNewUser} />
          <Route exact path="/administration/users/:userId/details" component={AdministrationUserDetails} />
          <Route exact path="/administration/sites/:siteId/details" component={AdministrationSitesDetails} />
          <Route exact path="/administration/sites/:siteId/users" component={AdministrationSitesUsers} />
          <Route exact path="/administration/sites/:siteId/plants" component={AdministrationSitesPlants} />
          <Route exact path="/sites/:siteId/plantcodes/:plantCode/formulas/:formulaId/details" component={FormulasDetails} />
          <Route exact path="/sites/:siteId/plantcodes/:plantCode/formulas/:formulaId/nutrients" component={FormulaNutrientsDetails} />
          <Route exact path="/sites/:siteId/plantcodes/:plantCode/formulas/:formulaId/ingredients" component={FormulaIngredientsDetails} />
          <Route exact path="/administration/sites/:siteId/plants/:plantCode/users" component={SitePlantUsers}/>
          <Route exact path="/administration/sites/:siteId/plants/:plantCode/details" component={SitePlantDetails}/>
        </Switch>
        <div>
          <hr style={{ width: '100%' }} />
          <footer>Formula Integration  {version}  © {(new Date().getFullYear())} Agrifood Alternative Technologies, SL & Caedis Integral Solutions, SL </footer>
        </div>

      </DrawerRouterContainer>
    </div>
  );
}

export default App;
